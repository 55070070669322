<template>
  <div>
    <b-modal
      v-model="show"
      id="modal-group"
      cancel-variant="outline-secondary"
      ok-title="Save "
      cancel-title="Close"
      centered
      size="lg"
      title="Group form"
    >
    <b-form>
  <b-form-group>
    <label :for="$t('Name')"> {{ $t('Name_of_group') }}:</label>
    <b-form-input
      :id="$t('Name')"
      type="text"
      :placeholder="$t('Name_of_group')"
      v-model="groupName"
    />
  </b-form-group>
  <b-form-group>
    <b-form
      ref="form"
      :style="{ height: trHeight }"
      class="repeater-form"
      @submit.prevent="repeateAgain"
    >
      <!-- Row Loop -->
      <b-row
        v-for="(item, index) in items"
        :id="item.id"
        :key="item.id"
        ref="row"
      >
        <!-- Item Name -->
        <b-col cols="4" class="pr-0">
          <b-form-group :label="$t('User_Name')" label-for="item-name">
            <b-form-input
              :id="$t('User_email')"
              type="text"
              :placeholder="$t('User_test')"
              v-model="item.name"
            />
          </b-form-group>
        </b-col>

        <!-- Item email -->
        <b-col cols="4" class="pr-0">
          <b-form-group :label="$t('User_email')" label-for="item-name">
            <b-form-input
              :id="$t('User_email')"
              type="text"
              v-model="item.email"
              :placeholder="$t('time.contact@gmail.com')"
            />
          </b-form-group>
        </b-col>
        <!-- Remove Button -->
        <b-col cols="2" class="">
          <b-button
            variant="gradient-danger"
            class="mt-0 mt-md-2"
            @click="removeItem(index)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </b-col>
        <b-col cols="2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="repeateAgain"
            class="mt-0 mt-md-2"
          >
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
        </b-col>
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>
    </b-form>
  </b-form-group>
</b-form>


      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            size="md"
            @click="addGroup()"
          >
           {{$t('Save')}} 
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      v-model="showUpdate"
      id="modal-groupUpdate"
      cancel-variant="outline-secondary"
      ok-title="Save "
      cancel-title="Close"
      centered
      size="lg"
      title="Group form"
    >
<b-form>
  <b-form-group>
    <label :for="$t('Name')"> {{ $t('Name_of_group') }}:</label>
    <b-form-input
      :id="$t('Name')"
      type="text"
      :placeholder="$t('Name_of_group')"
      v-model="updateGroup.groupName"
    />
  </b-form-group>
  <b-form-group>
    <b-form
      ref="form"
      :style="{ height: trHeight }"
      class="repeater-form"
      @submit.prevent="repeateAgain"
    >
      <b-row
        v-for="(item, index) in updateGroup.itemsUpdateGroup"
        :id="item.id"
        :key="item.id"
        ref="row"
      >
        <b-col cols="4" class="pr-0">
          <b-form-group :label="$t('User_Name')" label-for="item-name">
            <b-form-input
              :id="$t('User_email')"
              type="text"
              :placeholder="$t('User_test')"
              v-model="item.name"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4" class="pr-0">
          <b-form-group :label="$t('User_email')" label-for="item-name">
            <b-form-input
              :id="$t('User_email')"
              type="text"
              v-model="item.email"
              :placeholder="$t('time.contact@gmail.com')"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2" class="">
          <b-button
            variant="gradient-danger"
            class="mt-0 mt-md-2"
            @click="removeItemUpdate(index)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </b-col>
        <b-col cols="2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="repeateAgainUpdate"
            class="mt-0 mt-md-2"
          >
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
        </b-col>
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>
    </b-form>
  </b-form-group>
</b-form>


      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            size="md"
            @click="updateGroupDetail()"
          >
               {{$t('Save')}} 
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-card-actions action-collapse title="Group">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              @input="advanceSearch"
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon mt-0 ml-1"
              v-b-modal.modal-group
            >
                          <!-- @click="UpdateCategoryModal(props.row)" -->
              <feather-icon icon="PlusCircleIcon" />
              <!-- add -->
            </b-button>
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
          //selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          //selectionInfoClass: 'custom-class',
          //selectionText: 'rows selected',
          //clearSelectionText: 'clear',
          //disableSelectInfo: true, // disable the select info panel on top
          //selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :group-options="{
          enabled: true,
        }"
      >
        <template slot="table-header-row" slot-scope="headerProps">
          <span class="my-fancy-class">
            {{ headerProps.row.label }}
            <span class="btn-table-groupe">
              <span class="btn-action-table">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="btn-icon"
                  v-b-modal.modal-groupUpdate
                  @click="updateGroupModal(headerProps.row)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
                <b-button
                  variant="gradient-danger"
                  class="btn-icon"
                  @click="deleteGroup(headerProps.row.id)"
                >
                  <!-- @click="DeleteCategory(props.row._id)" -->

                  <feather-icon icon="TrashIcon" />
                </b-button>
              </span>
            </span>
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <!-- <span v-if="props.column.field === 'fullName'" class="text-nowrap">
            <b-avatar :src="props.row.avatar" class="mx-1" />
            <span class="text-nowrap">{{ props.row.fullName }}</span>
          </span> -->

          <!-- Column: Action -->

          <!-- Column: Common -->
          <span>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-actions>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BForm,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BButton,
  BModal,
  VBModal,
  BCol,
  BContainer,
  BRow,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import { heightTransition } from "@core/mixins/ui/transition";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default {
  components: {
    BForm,
    BCol,
    BContainer,
    BRow,
    BButton,
    BModal,
    VBModal,
    Ripple,
    BCardActions,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
  },
  data() {
    return {
      show: false,
      showUpdate: false,
      updateGroup: {
        id: "",
        groupName: "",
        itemsUpdateGroup: [
          {
            id: 1,
            email: "",
            name: "",
          },
        ],
      },
      groupName: "",
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Date",
          field: "dateOfCreation",
          formatFn: this.formatFn,
        },
      ],
      rows: [],
      searchTerm: "",
      items: [
        {
          id: 1,
          email: "",
          name: "",
        },
      ],
      nextTodoId: 2,
    };
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getGroup();
    window.addEventListener("resize", this.initTrHeight);
  },
  mounted() {
    this.initTrHeight();
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    formatFn: function(value) {
      return new Date(value) ;
    },
    advanceSearch(val) {
      this.searchTerm = val;
    },
    getGroup() {
      this.rows = [];
      axios
        .get(
          "https://backendapinodejs.timecheckit.com/api/groups?user=" +
            JSON.parse(localStorage.getItem("userData"))._id
        )
        // .get("https://backendapinodejs.timecheckit.com/api/groups")
        .then((response) => {
          response.data.data.forEach((element) => {
            this.rows.push({
              mode: "span",
              html: false,
              label: element.groupName,
              children: element.groupMembers,
              id: element._id,
            });
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      console.log("groupe", this.rows);
    },
    addGroup() {
      console.log("groupe 1", {
        groupName: this.groupName,
        user: JSON.parse(localStorage.getItem("userData"))._id,
        groupMembers: this.items,
      });
      var groupeUsers=[]
      this.items.forEach(element => {
        groupeUsers.push({
          email: element.email,
          name: element.name
        })
      });
      axios
        .post("https://backendapinodejs.timecheckit.com/api/groups", {
          groupName: this.groupName,
          user: JSON.parse(localStorage.getItem("userData"))._id,
          groupMembers: groupeUsers,
        })
        .then((response) => {
          this.getGroup();
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `New group created`,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    updateGroupModal(group) {
      console.log("group 3", group);
      this.updateGroup.id = group.id;
      this.updateGroup.itemsUpdateGroup = [];
      this.updateGroup.groupName = group.label;
      group.children.forEach((element, index) => {
        this.updateGroup.itemsUpdateGroup.push({
          email: element.email,
          name: element.name,
          id: index,
          dateOfCreation: element.dateOfCreation,
        });
      });
    },
    updateGroupDetail() {
      axios
        .put(
          "https://backendapinodejs.timecheckit.com/api/groups/" +
            this.updateGroup.id,
          this.updateGroup
        )
        .then((response) => {
           this.show = false;
          console.log("profile2", response);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Group updated`,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    deleteGroup(id) {
      console.log(this.items, this.groupName);
      axios
        .delete("https://backendapinodejs.timecheckit.com/api/groups/" + id)
        .then((response) => {
          console.log("group delete", response);
          this.getGroup();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Group deleted`,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    deleteUserGroup() {
      console.log(this.items, this.groupName);
      // axios
      //   .delete("https://backendapinodejs.timecheckit.com/api/group", {
      //   })
      //   .then((response) => {
      //     console.log("profile2", response);
      //   })
      //   .catch((error) => {
      //     console.error("There was an error!", error);
      //   });
    },

    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },

    repeateAgain() {
      // this.updateGroup.itemsUpdateGroup.push({
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },

    repeateAgainUpdate(){ 
    this.updateGroup.itemsUpdateGroup.push({
        id: (this.nextTodoId += this.nextTodoId),
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItemUpdate(index) {
      this.updateGroup.itemsUpdateGroup.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";

@import "@core/scss/vue/libs/vue-good-table.scss";
@import "~@radial-color-picker/vue-color-picker/dist/vue-color-picker.min.css";

.btn-delete-all {
  margin-left: 3%;
}
.btn-action-table button {
  margin-left: 10px;
}
.btn-action-table {
  display: flex;
}
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.pr-0 {
  padding-right: 0 !important;
}
.btn-table-groupe {
  float: right;
}
</style>
